import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import authReducer from "./authReducer";
import settingsReducer from "./settingsReducer";
import messageReducer from "./messageReducer";
import languageReducer from "./languageReducer";

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  settings: settingsReducer,
  message: messageReducer,
  language: languageReducer,
});

export default rootReducer;