import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { classPrefix } from "src/config/appConfig";
import AppLocale from "src/lang";
import NotificationToster from "src/components/notification-toaster/notificationToster";
import LocalizationService from "src/services/api/localization.service";
import { setLanguages } from "src/redux/actions/languageAction";
import { logout } from "src/redux/actions/authAction";
import AuthService from "src/services/api/auth.service";

const TheLayout = React.lazy(() => import('../TheLayout'));

// Pages
const Login = React.lazy(() => import('../../views/pages/login/Login'));

const RestrictedRoute = ({ component: Component, location, token, permission, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                token && permission
                    ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
            }
        />
    )
}

const MainApp = ({ token, permission, locale, logout, setLanguages }) => {

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const currentAppLocale = AppLocale[locale.locale];

    const handleIntlError = (e) => {
        if (e.code === ReactIntlErrorCode.MISSING_DATA) {
            return
        }
        console.error(e)
    }

    useEffect(() => {
        if (location.pathname === '/') {
            if (token === null || permission == null) {
                history.push('/login');
            }
        }
    }, [token, permission, location, history]);

    useEffect(() => {
        if (token && permission) {
            // AuthService.verifyToken().then(
            //     (data) => {
            //         return Promise.resolve();
            //     },
            //     (error) => {
            //         console.log(error);
            //         logout();
            //         return Promise.reject();
            //     }
            // );
            LocalizationService.getActiveList().then(
                (data) => {
                    if (data) {
                        // console.log('data', data);
                        let dummyLanguages = [];
                        data.localizations && data.localizations.length > 1 && data.localizations.map((language) => {
                            dummyLanguages.push(language.language_code);
                        })
                        setLanguages(dummyLanguages);
                    }
                    return Promise.resolve();
                },
                (error) => {
                    console.log(error);
                    return Promise.reject();
                }
            );
        }
    }, [token, permission]);

    return (<>
        <IntlProvider
            defaultLocale="en"
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
            onError={handleIntlError}
        >
            <div id={`__${classPrefix}`}>
                <Switch location={location}>
                    <Route exact path='/login' component={Login} />
                    {/* <Route exact path='/forgot-password' component={ForgotPassword} /> */}
                    <RestrictedRoute path={`${match.url}`} token={token} permission={permission} location={location} component={TheLayout} />
                </Switch>

                <NotificationToster />
            </div>
        </IntlProvider>
    </>)
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        permission: state.auth.permission,
        locale: state.settings.locale
    };
};

export default connect(mapStateToProps, { logout, setLanguages })(MainApp);
