import {
  SET_MESSAGE, 
  CLEAR_MESSAGE
} from "../../constants/actionTypes";

const INIT_STATE = {
  message: {
    type: "info",
    title: "",
    body: ""
  }
};

const messageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
      }
    default:
      return state;
  }
};

export default messageReducer;
